import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { IoClose, IoMenu } from 'react-icons/io5';
import './NavBar.css';

function NavBar({ isDarkMode, toggleDarkMode }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 992);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.pageYOffset);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 992);
    };

    const handleScroll = () => {
      if (window.pageYOffset > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
      setLastScrollY(window.pageYOffset);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleLogoClick = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNavClick = (href) => {
    return (event) => {
      event.preventDefault();
      const targetElement = document.querySelector(href);
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setMenuOpen(false);
    }
  };

  return (
    <Navbar
      className={`navbar-blur ${showNavbar ? 'show' : 'hide'}`}
      bg="transparent"
      variant={isDarkMode ? 'dark' : 'light'}
      expand="lg"
      fixed="top"
      expanded={menuOpen}
    >
      <div className="navbar-brand-container">
        <Navbar.Brand onClick={handleLogoClick}>
          <img
            src="/Images/arborwhite6.png"
            width="170"
            height="120"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <div className="phone-number">
          <a href="tel:+19255955366">(925)-595-5366</a>
        </div>
      </div>
      <Navbar.Toggle
        style={{ color: 'white', border: 'none', outline: 'none', boxShadow: 'none', marginRight: 10 }}
        aria-controls="basic-navbar-nav"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? <IoClose size={30} /> : <IoMenu size={30} />}
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={`ms-auto ${isCollapsed ? 'ms-auto-collapsed' : ''}`}>
          {[
            { name: 'Flower', href: '#flower' },
            { name: 'Solventless', href: '#solventless' },
            { name: 'Concentrates', href: '#concentrates' },
            { name: 'Cartridges', href: '#cartridges' },
            { name: 'Edibles', href: '#edibles' },
            { name: 'Prerolls', href: '#prerolls' },
          ].map((item) => (
            <Nav.Link
              key={item.name}
              href={item.href}
              style={{ color: 'white' }}
              onClick={handleNavClick(item.href)}
            >
              {item.name}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;