import React, { useState, useEffect } from 'react'
import background from '../trees.jpg'
import NavBar from '../components/NavBar'
import Home from '../components/Home'
import Products from '../components/Products'
import Solventless from '../components/Solventless'
import axios from 'axios'
import Flower from '../components/Flower'
import Footer from '../components/Footer'
import LoadingScreen from '../components/LoadingScreen'

const Arbor = ({flowerMenuItems, prerollMenuItems, solventlessMenuItems}) => {
  const [flowerItems, setFlowerItems] = useState([]);
  const [solventlessItems, setSolventlessItems] = useState({});
  const [concentratesItems, setConcentratesItems] = useState([]);
  const [cartridgesItems, setCartridgesItems] = useState([]);
  const [ediblesItems, setEdiblesItems] = useState([]);
  const [prerollsItems, setPrerollsItems] = useState([]);
  const [solventlessCartItems, setSolventlessCartItems] = useState({});
  const [solventlessBrandOrder, setSolventlessBrandOrder] = useState([]);
  const [solventlessCartBrandOrder, setSolventlessCartBrandOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getProducts = async () => {
    try {
      setIsLoading(true);
      const [productsResponse, orderResponse, solventlessResponse] = await Promise.all([
        axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/products'),
        axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/order'),
        axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/solventless-products')
      ]);
      const { data } = productsResponse;
      const orderData = orderResponse.data;
      const { solventless, solventlessCartridges, brandOrderSolventless, brandOrderSolventlessCart } = solventlessResponse.data;

      // Filter function to keep only products with reno === true
      const filterRenoProducts = (products) => products.filter(product => product.reno === true);

      // Process and set items for each category, filtering for reno === true
      setFlowerItems(orderProducts(filterRenoProducts(data.flower), orderData.productOrder.flower));
      setConcentratesItems(orderProducts(filterRenoProducts(data.concentrate), orderData.productOrder.concentrate));
      setCartridgesItems(orderProducts(filterRenoProducts(data.cartridge), orderData.productOrder.cartridge));
      setEdiblesItems(orderProducts(filterRenoProducts(data.edible), orderData.productOrder.edible));
      setPrerollsItems(orderProducts(filterRenoProducts(data.preroll), orderData.productOrder.preroll));

      // Process solventless items, filtering for reno === true
      const filteredSolventless = {};
      Object.keys(solventless).forEach(brand => {
        const filteredBrandProducts = solventless[brand].filter(product => product.reno === true);
        if (filteredBrandProducts.length > 0) {
          filteredSolventless[brand] = filteredBrandProducts;
        }
      });
      setSolventlessItems(filteredSolventless);
      setSolventlessBrandOrder(brandOrderSolventless.filter(brand => filteredSolventless[brand]?.length > 0));

      // Process solventless cartridges, filtering for reno === true
      const filteredSolventlessCartridges = {};
      Object.keys(solventlessCartridges).forEach(brand => {
        const filteredBrandProducts = solventlessCartridges[brand].filter(product => product.reno === true);
        if (filteredBrandProducts.length > 0) {
          filteredSolventlessCartridges[brand] = filteredBrandProducts;
        }
      });
      setSolventlessCartItems(filteredSolventlessCartridges);
      setSolventlessCartBrandOrder(brandOrderSolventlessCart.filter(brand => filteredSolventlessCartridges[brand]?.length > 0));

      setIsLoading(false);
    } catch (error) {
      console.error(`Error occurred while fetching products: ${error}`);
      setIsLoading(false);
    }
  };

  const orderProducts = (products, order) => {
    if (!order || order.length === 0) return products;
    const productMap = new Map(products.map(p => [p.productId, p]));
    return order
      .filter(id => productMap.has(id))
      .map(id => productMap.get(id))
      .concat(products.filter(p => !order.includes(p.productId)));
  };

  useEffect(() => {
    getProducts();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <NavBar />
      <Home />
      <div id="flower" className="gradient-section gradient-section-1">
        <Flower menuItems={flowerItems} title={"Flower"} />
      </div>
      <div id="solventless" className="gradient-section gradient-section-2">
        <Solventless 
          menuItems={solventlessItems} 
          brandOrder={solventlessBrandOrder} 
          title={"Solventless Menu"} 
          isSolventlessCart={false}
        />
      </div>
      <div id="concentrates" className="gradient-section gradient-section-3">
        <Products menuItems={concentratesItems} title={"Concentrates"} />
      </div>
      <div id="cartridges" className="gradient-section gradient-section-4">
        <Solventless 
          menuItems={solventlessCartItems} 
          brandOrder={solventlessCartBrandOrder} 
          title={"Solventless Cartridges"} 
          isSolventlessCart={true}
        />
        <Products menuItems={cartridgesItems} title={"Cartridges"} />
      </div>
      <div id="edibles" className="gradient-section gradient-section-5">
        <Products menuItems={ediblesItems} title={"Edibles"} />
      </div>
      <div id="prerolls" className="gradient-section gradient-section-6">
        <Products menuItems={prerollsItems} title={"Prerolls"} />
      </div>
      <Footer/>
    </div>
  )
}

export default Arbor