import React, { useState, useRef } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import './Products.css';
import { motion, useInView } from 'framer-motion';

const Products = ({ menuItems, title }) => {
  const [toggledIndex, setToggledIndex] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState('All');
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.1 });

  const handleCardClick = (index) => {
    setToggledIndex(toggledIndex === index ? null : index);
  };

  const parseDescription = (description, isEdible) => {
    if (isEdible) {
      const [desc, dosage] = description.split(':').map(item => item.trim());
      return { description: desc, dosage };
    }

    const parts = description.split('}');
    if (parts.length > 1) {
      return {
        lineage: parts[0].trim() + '}',
        description: parts.slice(1).join('}').trim()
      };
    }
    return {
      lineage: '',
      description: description.trim()
    };
  };

  const cleanupBrand = (brand) => {
    if (!brand) return '';
    return brand.split('-')[0].trim();
  };

  const filteredItems = menuItems.filter(item => 
    (item.inStock === "true" || item.inStock !== "true" ) && 
    (selectedBrand === 'All' || cleanupBrand(item.brand) === selectedBrand)
  );

  const brands = ['All', ...new Set(menuItems.map(item => cleanupBrand(item.brand)))];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div 
      className='product-container'
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <center>
        <motion.h2 
          className='product-title' 
          style={{ color: 'white' }}
          variants={itemVariants}
        >
          {title}
        </motion.h2>
        <Row>
          {filteredItems.map((item, index) => {
            let prices;
            try {
              prices = JSON.parse(item.prices);
            } catch (e) {
              console.error("Failed to parse prices for item", item, e);
              prices = [];
            }

            const twoGramPrice = prices.find(p => p.quantity === "2g");
            const eachPrice = prices.find(p => p.quantity === "Each");
            const twoPrice = prices.find(p => p.quantity === "2");

            const isEdible = title === 'Edibles';
            const isCartridge = title === 'Cartridges';
            const parsedDescription = parseDescription(item.description, isEdible);
            const cleanedBrand = cleanupBrand(item.brand);

            return (
              <Col sm={12} md={6} lg={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Card className={`product-card ${toggledIndex === index ? 'show-description' : ''}`}>
                    <div className="product-image-container" onClick={() => handleCardClick(index)}>
                      <Card.Img variant="top" src={item.imageurl} className="product-img-top" />
                      <div className="product-description">
                        {!isEdible && parsedDescription.lineage && (
                          <div className="product-lineage">
                            <strong>Lineage:</strong> <span>{parsedDescription.lineage}</span>
                          </div>
                        )}
                        <div className="product-description-text">
                          <strong>Description:</strong> <span>{parsedDescription.description}</span>
                        </div>
                        {isEdible && parsedDescription.dosage && (
                          <div className="product-dosage">
                            <strong>Dosage:</strong> <span>{parsedDescription.dosage}</span>
                          </div>
                        )}
                      </div>
                      {cleanedBrand === 'Potent Labs' && <img src="/Images/b2g1.png" alt="Buy 2 Get 1 Free" className="b2g1-icon" />}
                    </div>
                    <Card.Body>
                      <div className="product-title-container">
                        <Card.Title className='product-title-item' style={{color: 'white'}}>
                          {item.strain}
                        </Card.Title>
                      </div>
                      <Card.Text className='product-brand' style={{color: 'white', fontWeight:'700'}}>{cleanedBrand}</Card.Text>
                      <div className='strain-price-container'>
                        <div className='center-wrapper'>
                          <div className="product-price-container">
                            {twoGramPrice && <Card.Text className="product-price-item">2G: ${twoGramPrice.price}</Card.Text>}
                            {eachPrice && <Card.Text className="product-price-item">${eachPrice.price} Each</Card.Text>}
                            {isCartridge && twoPrice && (
                              <Card.Text className="product-price-item">${twoPrice.price / 2} Each (2+)</Card.Text>
                            )}
                          </div>
                          {item.onSale === 'true' && (
                            <img 
                              src="/Images/saletagright.png" 
                              alt="Sale" 
                              className="product-sale-tag"
                            />
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            )
          })}
        </Row>
      </center>
    </motion.div>
  );
};

export default Products;