import React, { useState, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Flower.css';
import { motion, useInView } from 'framer-motion';

const Flower = ({ menuItems, title }) => {
  const [toggledIndex, setToggledIndex] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.1 });

  const handleCardClick = (index) => {
    setToggledIndex(toggledIndex === index ? null : index);
  };

  const parseDescription = (description) => {
    const parts = description.split('}');
    if (parts.length > 1) {
      return {
        lineage: parts[0].trim() + '}',
        description: parts.slice(1).join('}').trim()
      };
    }
    return {
      lineage: '',
      description: description.trim()
    };
  };

  const cleanupBrand = (brand) => {
    if (!brand) return '';
    return brand.split('-')[0].trim();
  };

  // const inStockItems = menuItems.filter(item => item.inStock === "true");

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100,
        duration: 0.4
      }
    }
  };

  return (
    <motion.div 
      className='flowerr'
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <center>
        <motion.h2 
          className='flower-title' 
          style={{ color: 'white' }}
          variants={itemVariants}
        >
          {title}
        </motion.h2>
        <Row>
          {menuItems.map((item, index) => {
            let prices;
            try {
              prices = JSON.parse(item.prices);
            } catch (e) {
              console.error("Failed to parse prices for item", item, e);
              prices = [];
            }

            const eighthPrice = prices.find(p => p.quantity === "1/8")?.price;
            const fullPrice = prices.find(p => p.quantity === "Full")?.price;
            const halfPrice = prices.find(p => p.quantity === "1/2")?.price;

            const priceCount = [eighthPrice, fullPrice, halfPrice].filter(Boolean).length;
            const priceClass = priceCount === 1 ? 'one-price' : priceCount === 2 ? 'two-prices' : 'three-prices';

            const { lineage, description } = parseDescription(item.description);
            const cleanedBrand = cleanupBrand(item.brand);

            return (
              <Col sm={12} md={6} lg={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Card className={`card ${toggledIndex === index ? 'show-description' : ''}`}>
                    <div className="card-image-container" onClick={() => handleCardClick(index)}>
                      <Card.Img variant="top" src={item.imageurl} className="card-img-top" />
                      <div className="card-description">
                        {lineage && (
                          <div className="lineage">
                            <strong>Lineage:</strong> <span>{lineage}</span>
                          </div>
                        )}
                        <div className="description">
                          <strong>Description:</strong> <span>{description}</span>
                        </div>
                      </div>
                    </div>
                    <Card.Body>
                      <Card.Title className='flowertit' style={{ color: 'white' }}>{item.strain}</Card.Title>
                      {cleanedBrand ? (
                        <Card.Text style={{ color: 'white' }}>{cleanedBrand}</Card.Text>
                      ) : (
                        <div style={{ height: '35px' }}></div>
                      )}
                      {item.onSale === 'true' && (
                        <img src="/Images/saletagleft.png" className="sale-tag"></img>
                      )}
                      <div className={`price-container ${priceClass}`}>
                        {eighthPrice && <div className="price-item">EIGHTH: ${eighthPrice}</div>}
                        {fullPrice && <div className="price-item full">FULL: ${fullPrice}</div>}
                        {halfPrice && <div className="price-item">HALF: ${halfPrice}</div>}
                      </div>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            )
          })}
        </Row>
      </center>
    </motion.div>
  );
};

export default Flower;