import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
// import loadingImage from '../components/arborblack5.png';

const LoadingScreen = () => {
  const [loadingText, setLoadingText] = useState('Loading');

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === 'Loading...') return 'Loading';
        return prevText + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-screen">
      <div className="loading-content">
        <img src="/Images/arborblack5.png" alt="Loading" className="loading-image" />
        <p className="loading-text">{loadingText}</p>
      </div>
    </div>
  );
};

export default LoadingScreen;